



















































































import { Vue, Component } from 'vue-property-decorator';
import { reportStores } from '@/store/poultry/report';
import { ReportResponse } from '@/models/poultry/report.model';
import SortIndicator from '@/components/poultry/SortIndicator.vue';

@Component({
  components: {
    SortIndicator
  }
})
export default class ReportTableHeader extends Vue {
  store = reportStores.list;
  detailStore = reportStores.details;
  allReportIds = [];
  checkedMap = {};

  get reports(): ReportResponse[] {
    return this.store.reports;
  }

  get isAllReportsChecked() {
    return this.store.reports.length === this.detailStore.reportIds.length && this.store.reports.length !== 0;
  }

  handleCheckedAllIds(e: any) {
    if (e.target.checked) {
      this.detailStore.addAllReportIds();
    } else {
      this.detailStore.resetReportIds();
    }
  }

  sort(sortType: any) {
    this.store.setSortType(sortType);
  }

  get currentSort() {
    return this.store.currentSort;
  }

  get currentSortOrder() {
    return this.store.currentSortOrder.reverse;
  }
}
