











import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import DropdownFilterResults from '@/components/poultry/DropdownFilterResults.vue';
import { auditorStores } from '@/store/poultry/auditor/index';
import { clientStores } from '@/store/poultry/client';
import { reportStores } from '@/store/poultry/report';

@Component({
  components: {
    DropdownFilterResults,
  },
})
export default class AuditorNameFilter extends Vue {
  auditorDetailsStore = auditorStores.details;
  clientDetailStore = clientStores.details;
  clientListStore = clientStores.list;
  reportListStore = reportStores.list;
  defaultAuditor: any[] = [];
  @Ref() resetDefaultDropdown!: any;

  // Array of objects of Auditor Name
  get auditorOptions(): any {
    return this.auditorDetailsStore.auditorsDropdown.map((auditor) => {
      return {
        text: auditor.userProfile.name ? auditor.userProfile.name : '-',
        value: auditor.id,
      }
    });
  }

  get currentClientId() {
    return this.reportListStore.apiParams.clientId;
  }

  @Watch('currentClientId')
  handleAuditorsDropdownOptionsChanged() {
    this.resetDefaultDropdown['selectedData'] = [];
  }

  handleAuditorsSelected(auditorIds: []) {
    this.reportListStore.updatePagination(this.reportListStore.defaultPagination);
    this.reportListStore.fetchAll({
      ...this.reportListStore.apiParams,
      auditorIds,
    });
  }
}
