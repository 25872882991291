




















































import { Vue, Component } from 'vue-property-decorator';
import { reportStores } from '@/store/poultry/report/';
import { ReportResponse } from '@/models/poultry/report.model';
import { Farm, FarmLocationDetails } from '@/models/poultry/farm.model';
import dayjs from 'dayjs';
import { getRowNumber } from '@/utils/pagination';

@Component({
  components: {},
})
export default class ReportTableBody extends Vue {
  listStore = reportStores.list;
  detailStore = reportStores.details;

  created() {
    this.listStore.fetchAll({
      ...this.listStore.apiParams,
    });
  }

  get reports(): ReportResponse[] | null {
    return this.listStore.sortedReports;
  }

  get pagination() {
    return this.listStore.pagination;
  }

  getRowNumber() {
    return getRowNumber(this.pagination);
  }

  isReportChecked(reportId: number) {
    return this.detailStore.reportIds.indexOf(reportId) !== -1;
  }

  getFarmName(farm: Farm): string {
    if (!farm) {
      return '-';
    }
    return farm.name;
  }

  getAuditScore(auditScore: number): string{
    if (auditScore === undefined){
      return '-';
    }

    return auditScore.toString() + '%';
  }

  getLocation(farm?: FarmLocationDetails): string {
    if (farm) {
      const farmLocationChunks = [];

      if (farm.city) {
        farmLocationChunks.push(farm.city.name);
      }
      if (farm.state) {
        farmLocationChunks.push(farm.state.name);
      }

      if (!farmLocationChunks.length) {
        return '-';
      } else {
        return farmLocationChunks.join(', ');
      }
    }
    
    return '-';
  }

  getFormattedDate(date: string) {
    return dayjs(date).format('DD MMM YYYY');
  }

  handleCheckboxClicked(id: number, e: any) {
    if (e.target.checked) {
      this.detailStore.addReportId(id);
    } else {
      this.detailStore.removeReportId(id);
    }
  }

  handleAuditScoreClicked(id: string) {
    this.$router.push({
      name: 'reports.details',
      params: {
        id,
      },
    });
  }
}
