import { AxiosResponse } from 'axios';

export function download(response: AxiosResponse) {
  const contentDisposition = response.headers['content-disposition'];

  if (contentDisposition === undefined) {
    throw new Error('Invalid server header');
  }

  const filename = contentDisposition.split('=')[1];
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
