














































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { reportStores } from '@/store/poultry/report/';
import DeleteReport from './DeleteReport.vue';
import { downloadReportById } from '@/store/poultry/report/report.download';
import DropdownFilter from '@/components/poultry/DropdownFilter.vue';
import ClientNameFilter from './ReportTableFilter/ClientNameFilter.vue';
import FarmNameFilter from './ReportTableFilter/FarmNameFilter.vue';
import AuditorNameFilter from './ReportTableFilter/AuditorNameFilter.vue';
import ReportTypeFilter from './ReportTableFilter/ReportTypeFilter.vue';
import MonthFilter from './ReportTableFilter/MonthFilter.vue';
import YearFilter from './ReportTableFilter/YearFilter.vue';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/store/poultry/endpoints';
import { ReportResponse } from '@/models/poultry/report.model';
import { DownloadDocumentType } from '@/constants';

@Component({
  components: {
    ClientNameFilter,
    FarmNameFilter,
    AuditorNameFilter,
    MonthFilter,
    YearFilter,
    ReportTypeFilter,
  },
})
export default class ReportPageHeader extends Vue {
  store = reportStores.list;
  detailStore = reportStores.details;
  showDeleteModal = false;
  totalCount = 0;
  keywords: string = '';

  mounted() {
    this.fetchTotalCount();
  }

  @Watch('reports')
  async fetchTotalCount() {
    try {
      const { data } = await axios.get(`${Endpoints.Reports}/poultry/total_count`);
      this.totalCount = data.totalCount;
    } catch (error) {
      console.error(error);
    }
  }

  get reports(): ReportResponse[] {
    return this.store.reports;
  }

  handleExportExcelClicked() {
    // TODO: Export each sequentially
    const requests = this.detailStore.reportIds.map((reportId) => {
      return downloadReportById(reportId, DownloadDocumentType.excel);
    });
  }

  handleExportPdfClicked() {
    const requests = this.detailStore.reportIds.map((reportId) => {
      return downloadReportById(reportId, DownloadDocumentType.pdf);
    });
  }

  handleDeleteReport() {
    this.showDeleteModal = true;
  }

  @Emit('onSearch')
  searchByKeywords() {
    return this.keywords;
  }

  @Emit('onDeleteReport')
  deleteReport() {}
}
