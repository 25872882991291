











import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import DropdownFilterResults from '@/components/poultry/DropdownFilterResults.vue';
import { farmStores } from '@/store/poultry/farm';
import { clientStores } from '@/store/poultry/client';
import { reportStores } from "@/store/poultry/report";

@Component({
  components: {
    DropdownFilterResults
  }
})
export default class FarmNameFilter extends Vue {
  farmDetailsStore = farmStores.details;
  clientDetailStore = clientStores.details;
  clientListStore = clientStores.list;
  reportListStore = reportStores.list;
  @Ref() readonly resetDefaultDropdown!: any;

  get currentClientId() {
    return this.reportListStore.apiParams.clientId;
  }

  @Watch('currentClientId')
  handleFarmsDropdownOptionsChanged() {
    this.resetDefaultDropdown['selectedData'] = [];
  }

  get farmOptions() {
    return this.farmDetailsStore.farmsDropdown.map(farm => {
      return {
        text: farm.name,
        value: farm.id
      }
    });
  }

  handleFarmsSelected(farmIds: []) {
    this.reportListStore.updatePagination(this.reportListStore.defaultPagination);
    this.reportListStore.fetchAll({
      ...this.reportListStore.apiParams,
      ...this.reportListStore.pagination,
      farmIds,
    });
  }
}
