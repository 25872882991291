











import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import DropdownFilterResults from '@/components/poultry/DropdownFilterResults.vue';
import { reportStores } from '@/store/poultry/report';
import { DropdownOption } from "@/models/poultry/dropdown.model";
import { clientStores } from "@/store/poultry/client";
import { MONTHS } from '@/utils/dayjs';

@Component({
  components: {
    DropdownFilterResults
  }
})
export default class MonthFilter extends Vue {
  reportListStore = reportStores.list;
  clientListStore = clientStores.list;
  defaultMonth: any[] = [];
  @Ref() readonly activeSelectedDropdown!: any;

  @Watch('reportListStore.apiParams.clientId')
  handleClientsDropdownChanged() {
    this.activeSelectedDropdown['selectedData'] = [];
  }

  // Array of objects of Month
  get monthOptions(): DropdownOption[] {
    return MONTHS;
  }

  filterMonthsList(months: []) {
    this.reportListStore.updatePagination(this.reportListStore.defaultPagination);
    this.reportListStore.fetchAll({
      ...this.reportListStore.apiParams,
      months,
    })
  }
}
