

































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Modal from '@/components/poultry/Modal.vue';
import Rewards from '@/datasource/rewards/common.reward.vue';
import VueRouter from 'vue-router';
import { reportStores } from '@/store/poultry/report/';
import { clientStores } from '@/store/poultry/client';
import { ToastProgrammatic as Toast } from 'buefy';

@Component({
  components: {
    Modal,
  },
})
export default class DeleteReport extends Vue {
  store = reportStores.details;
  showDeleteModal = false;

  @Prop()
  isMultiple!: boolean;

  async handleDeleteReport() {
    try {
      await this.store.delete();
      if (this.isMultiple) {
        Toast.open({
          type: 'is-danger',
          message: 'Audit Reports deleted',
        });

        await reportStores.list.fetchAll({
          ...reportStores.list.apiParams,
          ...reportStores.list.pagination,
        });

        const species = (this.$router.currentRoute.fullPath.split('/'))[1];

        clientStores.list.setSpecies(species)
        await clientStores.list.fetchClients();
        this.store.resetReportIds();
        this.closeDeleteModal();
      } else {
        this.$router.push({
          name: 'reports',
        });
        Toast.open({
          type: 'is-danger',
          message: 'Audit Report deleted',
        });
      }
    } catch (error) {
      Toast.open({
        type: 'is-danger',
        message: 'Report cannot be deleted',
      });
    }
  }

  @Emit('onClose')
  closeDeleteModal() {}
}
