











import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import DropdownFilterResults from '@/components/poultry/DropdownFilterResults.vue';
import { reportStores } from '@/store/poultry/report';
import { reportTypeStores } from '@/store/poultry/reportType';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { clientStores } from "@/store/poultry/client";
import { ReportTypeNames } from "@/constants";

@Component({
    components: {
        DropdownFilterResults
    }
})

export default class ReportTypeFilter extends Vue {
    reportListStore = reportStores.list;
    reportTypeListStore = reportTypeStores.list;
    clientListStore = clientStores.list;
    reportTypeOptions: DropdownOption[] = [];

    @Ref() readonly activeSelectedDropdown!: any;

    async created() {
        await this.reportTypeListStore.fetchAll();
        this.getReportTypeOptions();
    }

    @Watch('reportListStore.apiParams.clientId')
    handleClientsDropdownChanged() {
        this.activeSelectedDropdown['selectedData'] = [];
    }

    getReportTypeOptions(){
        let reportTypeOptionText = '';

        this.reportTypeListStore.reportTypes.forEach(reportType => {

            switch(reportType.name){
                case ReportTypeNames.water:
                    reportTypeOptionText = 'Drinking Water';
                    break;
                case ReportTypeNames.injectable:
                    reportTypeOptionText = 'Injectable';
                    break;
                case ReportTypeNames.hatchery:
                    reportTypeOptionText = 'Hatchery Spray';
                    break;
                case ReportTypeNames.cnd:
                    reportTypeOptionText = 'Cleaning and Disinfection';
                    break;
                case ReportTypeNames.ipm:
                    reportTypeOptionText = 'Integrated Pest Management';
                    break;
                case ReportTypeNames.labOnWheel:
                    reportTypeOptionText = 'Lab on Wheel Audit';
                    break;
                case ReportTypeNames.pea:
                    reportTypeOptionText = 'Pipped Embryo Analysis';
                    break;
                case ReportTypeNames.eba:
                    reportTypeOptionText = 'Egg Break Out Analysis';
                    break;
                default: 
                    reportTypeOptionText = '';
                    break;
            }

            this.reportTypeOptions.push(
                {
                    text: reportTypeOptionText,
                    value: reportType.id.toString(),
                }
            );
        });
    }

    filterReportTypesList(reportTypeIds: []) {
        this.reportListStore.updatePagination(this.reportListStore.defaultPagination);
        this.reportListStore.fetchAll({
            ...this.reportListStore.apiParams,
            reportTypeIds,
        });
    }
}
