import { Endpoints } from './../endpoints';
import { axios } from '@/utils/axios';
import { download } from '@/utils/download';
import { DownloadDocumentType } from '@/constants';

export async function downloadReportById(reportId: number, downloadDocumentType: DownloadDocumentType) {
  let requestUri = 'download/excel';

  if (downloadDocumentType === DownloadDocumentType.pdf){
    requestUri = 'download/pdf'
  }
  const response = await axios.get(`${Endpoints.Reports}/poultry/${reportId}/${requestUri}`, {
    responseType: 'blob'
  });

  download(response);
}
