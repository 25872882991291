











import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import DropdownFilterResults from '@/components/poultry/DropdownFilterResults.vue';
import { reportStores } from '@/store/poultry/report';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { clientStores } from "@/store/poultry/client";

@Component({
  components: {
    DropdownFilterResults
  }
})
export default class YearFilter extends Vue {
  reportListStore = reportStores.list;
  clientListStore = clientStores.list;
  yearsOption: DropdownOption[] = [];
  defaultYear: any[] = [];

  @Ref() readonly activeSelectedDropdown!: any;

  async created() {
    await this.generateYear();
  }

  @Watch('reportListStore.apiParams.clientId')
  handleClientsDropdownChanged() {
    this.activeSelectedDropdown['selectedData'] = [];
  }

  generateYear() {
    let startYear = 2020;
    let endYear = 2050;

    while (startYear <= endYear) {
      // Array of objects of Year
      this.yearsOption.push({
        text: startYear.toString(),
        value: startYear.toString()
      });
      startYear++;
    }
  }

  filterYearsList(years: []) {
    this.reportListStore.updatePagination(this.reportListStore.defaultPagination);
    this.reportListStore.fetchAll({
      ...this.reportListStore.apiParams,
      years
    });
  }
}
