








import { Vue, Component } from 'vue-property-decorator';
import ReportTableHeader from './ReportTableHeader.vue';
import ReportTableBody from './ReportTableBody.vue';

@Component({
  components: {
    ReportTableHeader,
    ReportTableBody
  }
})
export default class ReportTable extends Vue {
}
