




















import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/poultry/layout/Page.vue';
import ReportTable from './ReportTable.vue';
import { reportStores } from '@/store/poultry/report';
import Pagination from '@/components/poultry/Pagination.vue';
import { PaginationModel } from '@/models/poultry/pagination.model';
import Modal from '@/components/poultry/Modal.vue';
import DeleteReport from './DeleteReport.vue';
import ReportPageHeader from './ReportPageHeader.vue';

@Component({
  components: {
    Page,
    ReportTable,
    Pagination,
    Modal,
    DeleteReport,
    ReportPageHeader,
  },
})
export default class Report extends Vue {
  listStore = reportStores.list;
  detailStore = reportStores.details;
  showDeleteModal = false;
  timeoutId: any;
  searchKeywords = '';

  created() {
    document.title = 'Elanco | Reports';
    this.detailStore.resetReportIds();
    this.listStore.updatePagination(this.listStore.defaultPagination);
    this.listStore.fetchAll({
      clientId: '',
      farmIds: [],
      auditorIds: [],
      reportTypeIds: [],
      months: [],
      years: []
    });
  }

  async searchByKeywords(inputKeywords: any) {
    if (!inputKeywords.trim()) {
      await this.listStore.fetchAll({
        clientId: '',
        farmIds: [],
        auditorIds: [],
        reportTypeIds: [],
        months: [],
        years: []
      });
    } else {
      this.searchKeywords = inputKeywords;
      this.listStore.searchByKeywords({
        keywords: inputKeywords,
        pagination: this.listStore.defaultPagination
      });
    }
  }

  handlePageCountChanged(params: PaginationModel) {
    this.listStore.updatePagination(params);
    this.listStore.fetchAll({
      ...this.listStore.apiParams,
    });
  }
}
