















































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { reportTypeStores } from '@/store/poultry/reportType';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { ReportTypeIds } from '@/constants';

@Component({})
export default class DropdownFilterResults extends Vue {
  @Prop() options!: DropdownOption[];
  @Prop() filterType!: string;
  @Prop() disabled!: boolean;
  @Prop() defaultPlaceholder!: string;
  @Prop() selectedOption!: DropdownOption[];

  reportTypeDetailStore = reportTypeStores.detail;

  keywords: string = '';
  isActive: any = false;
  selected: any = null;
  selectedData: any[] = [];

  created() {
    this.selectedData = [];
    this.initializeDefaultOption();
  }

  mounted() {
    document.addEventListener("click", this.closeDropdown);
  }

  destroyed() {
    document.removeEventListener("click", this.closeDropdown);
  }

  changeBorder(){
    return this.getReportStyleByReportTypeId(String(this.reportTypeDetailStore.reportTypeId));
  }

  @Emit('onFilterData')
  clearData() {
    return this.selectedData = [];
  }

  @Emit('onFilterData')
  filterData(filteredData: []) {
    return filteredData;
  }

  @Emit('onClose')
  closeDropdown(e: any) {
    if (!this.$el.contains(e.target) || e.target.id === 'save-filter--button' || e.target.id === 'clear-filter--button') {
      this.isActive = false;
    }
    return this.selectedData;
  }

  @Emit('onSearch')
  handleFormSubmitted() {
    return this.keywords;
  }

  initializeDefaultOption() {
    if (this.selectedOption) {
      this.selectedData = this.selectedOption;
    }
  }

  getReportStyleByReportTypeId(reportTypeId: string): string{
    let style = '';

    switch(reportTypeId){
      case ReportTypeIds.water:
        style = 'water';
        break;
      case ReportTypeIds.injectable:
        style = 'injectable';
        break;
      case ReportTypeIds.hatchery:
        style = 'hatchery';
        break;
      case ReportTypeIds.cnd:
        style = 'cleaning';
        break;
      case ReportTypeIds.ipm:
        style = 'ipm';
        break;
      case ReportTypeIds.labOnWheel:
        style = 'low';
        break;
      case ReportTypeIds.eba:
        style = 'eba';
        break;
      case ReportTypeIds.pea:
        style = 'pea';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  changeCheckBoxColor(){
    let checkboxColor = this.getReportStyleByReportTypeId(String(this.reportTypeDetailStore.reportTypeId));
    return `is-${checkboxColor}`;
  }

  changeInvertedColor(){
    let invertedColor = this.getReportStyleByReportTypeId(String(this.reportTypeDetailStore.reportTypeId));
    return `${invertedColor}-inverted`;
  }

  changeButtonColor(){
    return this.getReportStyleByReportTypeId(String(this.reportTypeDetailStore.reportTypeId));
  }
}
